import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import Image from "next/future/image";
import viewBtnArrow from "../../static/images/viewbtnarrow.svg";
import viewBtnArrowBlack from "../../static/images/viewbtnarrowblack.svg";
import moment from "moment";
import { getStatusClass, _parseFloat } from "utils/helpers";

export default function TimerComponant(props) {
  const Ref = useRef(null);
  const {
    start_time,
    end_time,
    componantFor,
    timerParantClassName,
    onChangeStatus,
    contract_status,
    contract_softCap,
    contract_hardCap,
    get_total_invested_amount,
  } = props;

  // console.log("yyyyyyy start_time",start_time)
  // console.log("yyyyyyy end_time",end_time)

  const [statusText, setStatusText] = useState("");
  const [ShowDateStatus, setShowDateStatus] = useState("");

  const [timerString, setTimerString] = useState("");
  const [timerObj, setTimerObj] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  const [changed, setChanged] = useState(false);
 
  // let log = {
  //   contract_status,
  //   start_time,
  //   end_time,
  // }

  // console.log("log",log)

  useEffect(() => {
    const now = new Date();
    //1:finalize clicked
    //2:cancel clicked

    let invested_amt = _parseFloat(get_total_invested_amount);
    let hardCap = _parseFloat(contract_hardCap);

    if (
      contract_status == 1 ||
      contract_status == 2 ||
      invested_amt == hardCap
    ) {
      setStatusText("ENDED");
      
      if (onChangeStatus) onChangeStatus("ENDED");
      return;
    }

    const currentMoment = moment(now); //.format("MM-DD-YYYY HH:mm:ss");
    const startMoment = moment(start_time); //.format("MM-DD-YYYY HH:mm:ss");
    const endMoment = moment(end_time); //

   

    // console.log("Timer: ----------Tmestamp----------------------");
    // console.log("Timer: currentMoment", currentMoment);
    // console.log("Timer: startMoment", start_time);
    // console.log("Timer: endMoment", end_time);
    // console.log("Timer: --------------------------------");
    // console.log("Timer: currentMoment", currentMoment);
    // console.log("Timer: startMoment", startMoment);
    // console.log("Timer: endMoment", endMoment);
    // console.log("Timer: --------------------------------");
    // console.log("Timer: contract_status", contract_status);

    if (currentMoment.isBefore(startMoment)) {
      //upcoming
      const futureDate = new Date(startMoment).getTime();
      setShowDateStatus("Sale start in");
      setStatusText("UPCOMING");
      if (onChangeStatus) onChangeStatus("UPCOMING");
      // console.log("ssssssss UPCOMING");

      CountDownTimer(futureDate);
    } else if (currentMoment.isBefore(endMoment)) {
      //live
      const futureDate = new Date(endMoment).getTime();
      CountDownTimer(futureDate);
      setShowDateStatus("Sale end in");
      setStatusText("LIVE");
      if (onChangeStatus) onChangeStatus("LIVE");

      // console.log("ssssssss LIVE");
    } else if (endMoment.isBefore(currentMoment)) {
      setShowDateStatus("Sale ended");
      setStatusText("ENDED");
      if (onChangeStatus) onChangeStatus("ENDED");

      // console.log("ssssssssFFFFF ENDED");
    }
  }, [start_time, end_time, changed, contract_status, get_total_invested_amount, contract_hardCap]);

  // }, [start_date, end_date]);

  function CountDownTimer(dt) {
    try {
      const end = new Date(dt);
      // console.log("countT end", end);
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      let timer;

      function showRemaining() {
        const now = new Date();
        const distance = end - now;

        // console.log("distance 123", distance);
        if (distance < 0) {
          clearInterval(timer);

          // const now = new Date();
          const currentMoment = moment(now);
          const endMoment = moment(end_time); //
          if (endMoment.isBefore(currentMoment)) {
            setShowDateStatus("Sale ended");
            setStatusText("ENDED");
            setTimerString("");
            if (onChangeStatus) onChangeStatus("ENDED");
          }

          // console.log("ssssssss ENDED showRemaining distance", distance);
          setChanged(true);
          // setTimerObj({
          //   days: "00",
          //   hours: '00',
          //   minutes: "00",
          //   seconds: "00",
          // });
          return;
        }

        const days = Math.floor(distance / _day)
          .toString()
          .padStart(2, "0");
        const hours = Math.floor((distance % _day) / _hour)
          .toString()
          .padStart(2, "0");
        const minutes = Math.floor((distance % _hour) / _minute)
          .toString()
          .padStart(2, "0");
        const seconds = Math.floor((distance % _minute) / _second)
          .toString()
          .padStart(2, "0");

        setTimerString(
          days.toString().padStart(2, "0") +
            ": " +
            hours.toString().padStart(2, "0") +
            ": " +
            minutes.toString().padStart(2, "0") +
            ": " +
            seconds.toString().padStart(2, "0")
        );

        setTimerObj({
          days: days.toString().padStart(2, "0"),
          hours: hours.toString().padStart(2, "0"),
          minutes: minutes.toString().padStart(2, "0"),
          seconds: seconds.toString().padStart(2, "0"),
        });
      }
      timer = setInterval(showRemaining, 1000);
    } catch (error) {}
  }

  // console.log("statusText13", statusText);

  if (componantFor && componantFor.toLocaleLowerCase() == "status") {
    return <span className={getStatusClass(statusText)}>{statusText} </span>;
  } else if (
    componantFor &&
    componantFor.toLocaleLowerCase() == "timer_with_separate_hhmmss"
  ) {
    return (
      <div className={timerParantClassName}>
        {/* <span className="show-date-str">{timerString}</span> */}
        <span className="timer-item days">
          {timerObj?.days} <span className="colorGrey fontNormal">D</span>{" "}
        </span>
        <span className="timer-item hours">
          {timerObj?.hours} <span className="colorGrey fontNormal">H</span>
        </span>
        <span className="timer-item minutes">
          {timerObj?.minutes} <span className="colorGrey fontNormal">M</span>
        </span>
        <span className="timer-item seconds">
          {timerObj?.seconds} <span className="colorGrey fontNormal">S</span>
        </span>
      </div>
    );
  } else {
    return null;
  }
}

// // How to use
// <TimerComponant
//   start_time={start_time}
//   end_time={end_time}
//   // end_date="2023-05-01T07:01:09.334Z"
//   onChangeStatus={(status) => {
//     setStatus(status);
//   }}

// // empty value return nothing to show (componantFor)

//   componantFor={"timer_with_separate_hhmmss"}
//   timerParantClassName="timer-container"
// />;

// end_date="2023-05-01T07:01:09.334Z"
// import React, { useRef, useState, useEffect } from "react";
// import moment from "moment";

// const TimerComponant = (props) => {
//   const { start_time, end_time, parantClassName, onChangeStatus } = props;
//   const Ref = useRef(null);
//   const [statusText, setStatusText] = useState("");

//   const [timerString, setTimerString] = useState("");

//   const [timerObj, setTimerObj] = useState({
//     days: "00",
//     hours: "00",
//     minutes: "00",
//     seconds: "00",
//   });

//   const [ShowDateStatus, setShowDateStatus] = useState("");

//   useEffect(() => {
//     const now = new Date();

//     const currentMoment = moment(now); //.format("MM-DD-YYYY HH:mm:ss");
//     const startMoment = moment(start_time); //.format("MM-DD-YYYY HH:mm:ss");
//     const endMoment = moment(end_time); //

//     if (currentMoment.isBefore(startMoment)) {
//       //upcoming
//       const futureDate = new Date(startMoment).getTime();
//       setShowDateStatus("Sale start in");
//       setStatusText("UPCOMING");
//       if (onChangeStatus) onChangeStatus("UPCOMING");

//       CountDownTimer(futureDate);
//     } else if (currentMoment.isBefore(endMoment)) {
//       //live
//       const futureDate = new Date(endMoment).getTime();
//       CountDownTimer(futureDate);
//       setShowDateStatus("Sale end in");
//       setStatusText("LIVE");
//       if (onChangeStatus) onChangeStatus("LIVE");
//     } else {
//       setShowDateStatus("Sale ended");
//       setStatusText("ENDED");
//       if (onChangeStatus) onChangeStatus("ENDED");
//     }
//   }, []);
//   // start_time, end_time
//   function CountDownTimer(dt) {
//     const end = new Date(dt);

//     const _second = 1000;
//     const _minute = _second * 60;
//     const _hour = _minute * 60;
//     const _day = _hour * 24;
//     let timer;

//     function showRemaining() {
//       const now = new Date();
//       const distance = end - now;
//       if (distance < 0) {
//         clearInterval(timer);
//         return;
//       }
//       const days = Math.floor(distance / _day);
//       const hours = Math.floor((distance % _day) / _hour);
//       const minutes = Math.floor((distance % _hour) / _minute);
//       const seconds = Math.floor((distance % _minute) / _second);
//       setTimerString(days + ": " + hours + ": " + minutes + ": " + seconds);
//       setTimerObj({
//         days: days.toString().padStart(2, "0"),
//         hours: hours.toString().padStart(2, "0"),
//         minutes: minutes.toString().padStart(2, "0"),
//         seconds: seconds.toString().padStart(2, "0"),
//       });
//     }
//     timer = setInterval(showRemaining, 1000);
//   }
//   return (
//     <div className={parantClassName}>
//       {/* <span className="show-date-str">{timerString}</span> */}
//       <span className="timer-item days">
//         {timerObj?.days} <span className="colorGrey fontNormal">D</span>{" "}
//       </span>
//       <span className="timer-item hours">
//         {timerObj?.hours} <span className="colorGrey fontNormal">H</span>
//       </span>
//       <span className="timer-item minutes">
//         {timerObj?.minutes} <span className="colorGrey fontNormal">M</span>
//       </span>
//       <span className="timer-item seconds">
//         {timerObj?.seconds} <span className="colorGrey fontNormal">S</span>
//       </span>
//     </div>
//   );
// };

// export default TimerComponant;

import React, { useState } from "react";
import Image from "next/future/image";
//IMPORT IMAGES
import sweplyLogo from "../../static/images/sweplyLogo.svg";
import logoBNB from "../../static/images/bnb.svg";
import viewBtnArrow from "../../static/images/viewbtnarrow.svg";
import viewBtnArrowBlack from "../../static/images/viewbtnarrowblack.svg";
import Link from "next/link";

//IMPORT SWIPER
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
//IMPORT COMPONENT
import EndSales from "./EndSales";
import {
  getStatusClass,
  timeStampToUtcDateObj,
  getNetworkChainImageSrc,
} from "utils/helpers";

import ProgressBar from "componants/common/ProgressBar";

import Status from "componants/common/Status";

export default function Cart(props) {
  const {
    index,
    status,
    isKYC,
    isAudit,
    isSAYF,
    tokenName,
    token_symbol,

    created_date,
    start_date,
    end_date,
    currency,
    Id,
    isLiked,
    isLike,
    fund_release_percent,
    handleLikeUnlike,
    detailPageLink,
    imageurl,
    progresspercentage,
    softcap,
    hardcap,
    title,
    filterLabel,
    get_total_invested_amount,
    contract_status,
    parantClassName,
    forFilter,
    selectedNetworkChainId,
  } = props;
  const [ShowDateStatus, setShowDateStatus] = useState("");
  const pull_status = (data) => {
    setShowDateStatus(data);
  };


  const [tempStatus, setStatus] = useState("");


  return (
    <div className={parantClassName ? parantClassName : ""}>
      <div className='latestLaunchpadBox'>
        <div className='latestLaunchpadHead'>
          <div className='latestLaunchpadroundBx'>
            <Image
              src={imageurl ? imageurl : sweplyLogo}
              alt='image'
              width={60}
              height={60}
              style={{ borderRadius: "50%" }}
            />
            {/* selectedNetworkChainId */}
            {getNetworkChainImageSrc({ chainId: selectedNetworkChainId }) && (
              <Image
                className='cryptoLogoSml'
                src={getNetworkChainImageSrc({
                  chainId: selectedNetworkChainId,
                })}
                alt=''
              />
            )}
          </div>

          <div className='latestLaunchpadDetails'>
            <div className={(isLiked && "likeBox active") || "likeBox"}>
              <i
                className='far fa-heart'
                onClick={() => handleLikeUnlike(Id, isLiked)}
              ></i>

              {start_date && end_date ? (
                <Status
                  start_time={start_date}
                  end_time={end_date}
                  componantFor={"status"}
                  contract_status={contract_status}
                  contract_softCap={softcap}
                  contract_hardCap={hardcap}
                  get_total_invested_amount={get_total_invested_amount}
                  onChangeStatus={(status) => {
                    setStatus(status);
                  }}
                />
              ) : null}
            </div>

            <div className='clearfix'></div>

            {isKYC && <span className='liveBox kyc font12'>KYC</span>}
          </div>
        </div>
        <h3 className='font18 fontBold'>
          {tokenName}
          {token_symbol && <span>({token_symbol})</span>}
        </h3>
        {title == "token-explorer" && (
          <>
            <div className='word-wrap'>
              <div className='contCodeShareBox bgDarkGrey d-inline'>
                <span>Contract :</span>{" "}
                <span className='contCodeDetails colorGrey'>
                  {props?.contractAddress?.slice(0, 6) +
                    "..." +
                    props?.contractAddress?.slice(30, 42) || "-"}
                </span>
              </div>
              <div className='activenetWorkBtn'>{props?.network || "-"}</div>
              <div className='exploreTotleSupplyAmt'>
                <span>Total Supply :</span> {props?.totalSupply || "-"}
              </div>
            </div>
          </>
        )}

        {hardcap && (
          <p className='font14'>
            {get_total_invested_amount || "0.0"}/{hardcap} {currency}
          </p>
        )}

        {fund_release_percent && (
          <p className='font14'>
            Project will receive {fund_release_percent}% at first release
          </p>
        )}

        {/* progresspercentage */}

        <div className='progressBarBox'>
          <ProgressBar
            parantClassName='launchpadProgressBar'
            progressPercent={Number(progresspercentage).toFixed(2)}
          />
          <div className='launchpadProgressBarReading'>
            <p className='leftReading'>
              {get_total_invested_amount || "0.0"} {currency}
            </p>
            <p className='rightReading'>
              {Number(hardcap).toFixed(2)} {currency}
            </p>
          </div>
        </div>
        {title == "token-explorer" && (
          <div className='saleEndTimerBox'>
            <p className='font16'></p>
            <Link className='' href={props?.tokenpageurl}>
              <span className='launchpadViewBtn cursor-pointer'>
                View
                <Image alt='' src={viewBtnArrow} className='viewBtnArrow' />
                <Image
                  alt=''
                  src={viewBtnArrowBlack}
                  className='viewBtnArrowBlack'
                />
              </span>
            </Link>
          </div>
        )}

        {index % 2 == 0 ? (
          <>
            <EndSales
              id={Id}
              // timer={"25:06:48"}
              viewUrl={"url"}
              start_time={start_date}
              end_time={end_date}
              created_date={created_date}
              detailPageLink={detailPageLink}
              saleStatus={pull_status}
              filterLabel={filterLabel}
              tokenName={tokenName}
              contract_status={contract_status}
              contract_softCap={softcap}
              contract_hardCap={hardcap}
              get_total_invested_amount={get_total_invested_amount}
            />
          </>
        ) : (
          <>
            <EndSales
              id={Id}
              // timer={"25:06:48"}
              viewUrl={"url"}
              start_time={start_date}
              end_time={end_date}
              created_date={created_date}
              detailPageLink={detailPageLink}
              saleStatus={pull_status}
              filterLabel={filterLabel}
              tokenName={tokenName}
              contract_status={contract_status}
              contract_softCap={softcap}
              contract_hardCap={hardcap}
              get_total_invested_amount={get_total_invested_amount}
            />
          </>
        )}
      </div>
    </div>
  );
}

import React, { useState, useEffect, useRef } from "react";
import Link from "next/link";
import Image from "next/future/image";
import viewBtnArrow from "../../static/images/viewbtnarrow.svg";
import viewBtnArrowBlack from "../../static/images/viewbtnarrowblack.svg";
import moment from "moment";
import { _parseFloat } from "utils/helpers";

export default function EndSales(props) {
  const Ref = useRef(null);
  const {
    start_time,
    created_date,
    end_time,
    id,
    detailPageLink,
    saleStatus,
    filterLabel,
    tokenName,
    contract_status,
    onChangeStatus,
    contract_softCap,
    contract_hardCap,
    get_total_invested_amount,
  } = props;

  // new --------------------------
  const [statusText, setStatusText] = useState("");
  const [timerString, setTimerString] = useState("");
  const [timerObj, setTimerObj] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  // /--------------
  const [ShowDate, setShowDate] = useState("");
  const [ShowDateStatus, setShowDateStatus] = useState("");

  const [changed, setChanged] = useState(false);

 
 
  // new------------------------

  useEffect(() => {
    const now = new Date();

    const currentMoment = moment(now); //.format("MM-DD-YYYY HH:mm:ss");
    const startMoment = moment(start_time); //.format("MM-DD-YYYY HH:mm:ss");
    const endMoment = moment(end_time); //

    let invested_amt = _parseFloat(get_total_invested_amount);
    let hardCap = _parseFloat(contract_hardCap);
    //1:finalize clicked
    //2:cancel clicked
    if (
      contract_status == 1 ||
      contract_status == 2 ||
      invested_amt == hardCap
    ) {
      setShowDateStatus("Sale ended");
      setStatusText("ENDED");
      if (onChangeStatus) onChangeStatus("ENDED");
      setTimerObj({
        days: "00",
        hours: "00",
        minutes: "00",
        seconds: "00",
      });
    } else if (currentMoment.isBefore(startMoment)) {
      //upcoming
      const futureDate = new Date(startMoment).getTime();
      setShowDateStatus("Sale start in");
      setStatusText("UPCOMING");
      if (onChangeStatus) onChangeStatus("UPCOMING");

      CountDownTimer(futureDate);
    } else if (currentMoment.isBefore(endMoment)) {
      //live
      const futureDate = new Date(endMoment).getTime();
      CountDownTimer(futureDate);
      setShowDateStatus("Sale end in");
      setStatusText("LIVE");
      if (onChangeStatus) onChangeStatus("LIVE");

    } else if (endMoment.isBefore(currentMoment)) {
      setShowDateStatus("Sale ended");
      setStatusText("ENDED");
      if (onChangeStatus) onChangeStatus("ENDED");

    }
  }, [start_time, end_time, changed, contract_status]);

 
  // new------------------------
  function CountDownTimer(dt) {
    try {
      const end = new Date(dt);
      const _second = 1000;
      const _minute = _second * 60;
      const _hour = _minute * 60;
      const _day = _hour * 24;
      let timer;

      function showRemaining() {
        const now = new Date();
        const distance = end - now;

        if (distance < 0) {
          clearInterval(timer);

          // const now = new Date();
          const currentMoment = moment(now);
          const endMoment = moment(end_time); //
          if (endMoment.isBefore(currentMoment)) {
            setShowDateStatus("Sale ended");
            setStatusText("ENDED");
            setTimerString("");
          }

          setChanged(true);
          // setTimerObj({
          //   days: "00",
          //   hours: "00",
          //   minutes: "00",
          //   seconds: "00",
          // });
          // setTimerString("00: 00: 00: 00");
          return;
        }

        const days = Math.floor(distance / _day)
          .toString()
          .padStart(2, "0");
        const hours = Math.floor((distance % _day) / _hour)
          .toString()
          .padStart(2, "0");
        const minutes = Math.floor((distance % _hour) / _minute)
          .toString()
          .padStart(2, "0");
        const seconds = Math.floor((distance % _minute) / _second)
          .toString()
          .padStart(2, "0");

        setTimerString(
          days.toString().padStart(2, "0") +
            ": " +
            hours.toString().padStart(2, "0") +
            ": " +
            minutes.toString().padStart(2, "0") +
            ": " +
            seconds.toString().padStart(2, "0")
        );

        setTimerObj({
          days: days.toString().padStart(2, "0"),
          hours: hours.toString().padStart(2, "0"),
          minutes: minutes.toString().padStart(2, "0"),
          seconds: seconds.toString().padStart(2, "0"),
        });
      }
      timer = setInterval(showRemaining, 1000);
    } catch (error) {}
  }

  return (
    <div className="saleEndTimerBox">
      <p className="font16">
        {ShowDateStatus}
        <span className="num-span">{timerString || ""}</span>
      </p>
      <Link className="" href={detailPageLink}>
        <span className="launchpadViewBtn cursor-pointer">
          View
          <Image alt="" src={viewBtnArrow} className="viewBtnArrow" />
          <Image alt="" src={viewBtnArrowBlack} className="viewBtnArrowBlack" />
        </span>
      </Link>
    </div>
  );
}

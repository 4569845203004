import React from "react";

const ProgressBar = (props) => {
  const { parantClassName, progressPercent } = props;
  return (
    <div className={parantClassName || "launchpadProgressBar"}>
      <div
        className="progressInner"
        style={{ width: progressPercent ? progressPercent + "%" : "0%" }}
      >
        <div className="launchpadProgressBarProgress">{progressPercent ? progressPercent : 0}%</div>
      </div>
    </div>
  );
};

export default ProgressBar;
